import React, {useState, useEffect} from "react";
import Card from "./components/card";
import Button from "./components/button";

import {call} from "../../../api/apiCall";
import {markTag} from "../../../api/tag";

const ModuleTagsPrinter = ({data}) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const getBaseData = async () => {
      try {
        const tagsToPrint = await call(data.url, "GET");

        setTags(tagsToPrint);
      } catch (e) {
        console.log("Erreur lors de la récupération des tags: ", e);
      }
    };

    if (data) {
      getBaseData();
    }
  }, [data]);

  const handleTagLoaded = (tag) => {
    document.dispatchEvent(new CustomEvent("onPoppinContentChange"));
  }

  const List = ({tags}) => {
    if (tags.length) {
      return (
        <div className="print-container">
          {tags.map(tag => (
            <div key={tag.id}>
              <Card tag={tag} handleTagLoaded={(tag) => handleTagLoaded(tag)} />
              <Button printAction={() => classicPrint(tag)} label={"Imprimer l'étiquette"} tagId={tag.id} />
            </div>
          ))}
        </div>
      );
    }

    return false;
  };

  const classicPrint = (tag) => {
    // Get tag iFrame
    markTag(tag.id);
    window.frames[`tag-print-${tag.id}`].focus();
    window.frames[`tag-print-${tag.id}`].print();
  }

  return (
    <List tags={tags} />
  );
};

export default props => <ModuleTagsPrinter {...props} />;
