import React, {useState} from "react";

const Button = ({printAction, label, tagId}) => {
  const [loading, setLoading] = useState(false);

  const print = async () => {
    setLoading(true);
    await printAction();
    setLoading(false);
  };

  return (
    <button
      className={`ui button black ${loading ? "loading" : ""}`}
      onClick={() => print()}
      disabled={loading}
    >
      {label}
    </button>
  );
};

export default Button;
